@layer components {
  .--control {
    @apply appearance-none relative inline-flex flex-row items-center justify-start shadow-none rounded min-h-40 min-w-40 align-top py-0 px-4;
  }

  .control {
    @apply --control;
  }

  .input,
  input {
    @apply --control w-full bg-gray-100 border-px border-gray-inputborder text-black;
  }

  textarea {
    @apply --control p-4 block max-w-full bg-gray-100 border-px border-gray-inputborder;

    resize: vertical;
    overflow: auto;
  }

  .input:active,
  .input:focus,
  input:active,
  input:focus,
  textarea:active,
  textarea:focus {
    @apply outline-none shadow-input-blue;
  }

  input::placeholder,
  .input::placeholder,
  textarea::placeholder,
  .select select::placeholder,
  .Select::placeholder {
    @apply text-gray-dark opacity-100;
  }

  input[disabled],
  textarea[disabled],
  select[disabled] {
    @apply cursor-not-allowed opacity-50;
  }

  input[type="text"],
  input[type="time"],
  .select select {
    @apply py-0 px-4;
  }

  input[type="checkbox"],
  input[type="radio"] {
    @apply min-h-40 h-10 min-w-40 w-10 m-0 align-baseline;
  }

  input[type="checkbox"]::-ms-check,
  input[type="radio"]::-ms-check {
    @apply hidden;
  }

  input[type="checkbox"]:checked::after,
  input[type="radio"]:checked::after {
    @apply absolute w-full h-full left-0 top-0 bottom-0 right-0 text-blue border-0 text-center;

    font-size: 32px;
    content: "\2714\fe0e";
  }

  input[type="checkbox"]:hover,
  input[type="checkbox"]:active,
  input[type="checkbox"]:focus,
  input[type="radio"]:hover,
  input[type="radio"]:active,
  input[type="radio"]:focus {
    @apply z-1 outline-none;
  }

  input[type="checkbox"]:hover + *,
  input[type="checkbox"]:active + *,
  input[type="checkbox"]:focus + *,
  input[type="radio"]:hover + *,
  input[type="radio"]:active + *,
  input[type="radio"]:focus + * {
    z-index: 2;
  }

  input[type="checkbox"]:hover,
  input[type="radio"]:hover {
    @apply border-blue cursor-pointer shadow-blue;
  }

  input[type="checkbox"]:active,
  input[type="checkbox"]:focus,
  input[type="radio"]:active,
  input[type="radio"]:focus {
    @apply shadow-input-blue;
  }

  label.checkbox {
    @apply flex flex-row items-center cursor-pointer mb-4;
  }

  label.checkbox > input {
    @apply shrink-0 mr-5;
  }

  fieldset {
    border: none;
  }

  select {
    @apply --control w-full h-10 block bg-gray-100 border-px border-gray-inputborder text-black;
  }

  select:hover {
    @apply cursor-pointer;
  }

  select::-ms-expand {
    @apply hidden;
  }

  input[data-has-value],
  .input[data-has-value] {
    @apply bg-yellow text-black;
  }

  input[type="date"] {
    @apply relative p-0 pl-2;
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    @apply text-gray-dark;
  }
  input[type="date"]::-webkit-clear-button {
    @apply text-gray-dark z-1 mr-2;
  }
  input[type="date"]::-webkit-inner-spin-button {
    @apply hidden;
  }

  /* Safari-specific selector */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      input[type="date"]::before {
        @apply flex flex-row items-center justify-start bg-transparent pointer-events-none;

        content: attr(placeholder);
      }
      input[type="date"][data-has-value]::before,
      input[type="date"]:active::before,
      input[type="date"]:focus::before {
        content: none;
      }
    }
  }

  .date-range input[type="date"] {
    @apply text-sm;
  }
  .date-range input[type="date"]:not([data-has-value]) {
    @apply text-blue;
  }

  .checkbox-row {
    @apply border-px border-gray-border rounded;
  }

  .checkbox-row label {
    @apply relative mb-0;
  }

  .checkbox-row label > span {
    @apply z-1 absolute text-blue ellipsis-overflow pointer-events-none;

    left: 16px;
    right: 26px;
    bottom: 50%;
    transform: translateY(50%);
  }

  .checkbox-row input[type="checkbox"],
  .checkbox-row input[type="radio"] {
    @apply mr-0 w-full h-full border-transparent rounded-0;
  }

  .checkbox-row input[type="checkbox"]:checked,
  .checkbox-row input[type="radio"]:checked {
    @apply bg-yellow;
  }

  .checkbox-row input[type="checkbox"]:checked + span,
  .checkbox-row input[type="radio"]:checked + span {
    @apply text-black;
  }

  .checkbox-row input[type="checkbox"]:checked::after,
  .checkbox-row input[type="radio"]:checked::after {
    content: none;
  }

  .checkbox-row input[type="checkbox"]:not(:checked) ~ .selected-label,
  .checkbox-row input[type="radio"]:not(:checked) ~ .selected-label,
  .checkbox-row input[type="checkbox"]:checked ~ .unselected-label,
  .checkbox-row input[type="radio"]:checked ~ .unselected-label {
    @apply hidden;
  }

  .file {
    @apply flex justify-start items-stretch relative mb-4;

    user-select: none;
  }

  .file .file-label {
    @apply flex items-stretch justify-start cursor-pointer relative overflow-hidden;
  }

  .file .file-label:hover .file-cta,
  .file .file-label:active .file-cta {
    filter: brightness(95%);
  }

  .file .file-label:focus-within,
  .file .file-input:hover,
  .file .file-input:active,
  .file .file-input:focus {
    box-shadow: 0 0 1px 1px theme("colors.blue.DEFAULT");
  }

  .file .file-input {
    @apply h-full w-full opacity-0 outline-none absolute top-0 left-0;
  }

  .file .file-cta {
    @apply min-h-9 inline-flex items-center justify-start px-6 bg-gray-100 border-px border-gray-border rounded rounded-br-0 rounded-tr-0;
  }

  .file .file-name {
    @apply min-h-9 max-w-300 px-6 py-3 border-px border-gray-border border-l-0 rounded rounded-bl-0 rounded-tl-0 block truncate;
  }

  .file .file-icon {
    @apply flex items-center justify-center h-6 w-6 mr-4;
  }

  input.search {
    @apply top-0 left-0 right-0 bottom-0 h-full w-full pl-9 pb-0;
  }

  input.search ~ .fa-search {
    @apply absolute left-0 w-9 h-full text-gray-icon;

    padding: 10px;
  }

  .star-input {
    @apply cursor-pointer text-gray-bg;

    font-size: 32px;
    -webkit-text-stroke: 1px theme("colors.gray.dark");
  }

  input[type="radio"]:checked ~ .star-input {
    @apply text-gray-dark;
  }

  input[type="radio"] ~ .star-input:hover,
  input[type="radio"] ~ .star-input:hover ~ .star-input,
  input[type="radio"] ~ .star-input:focus,
  input[type="radio"] ~ .star-input:focus ~ .star-input {
    @apply text-blue;
  }

  .tag-filter input[type="checkbox"] {
    @apply w-full h-full absolute left-0 top-0 right-0 bottom-0 cursor-pointer border-none rounded-md;

    transition: 0.1s ease-in-out all;
  }
  .tag-filter input[type="checkbox"] + label {
    @apply relative w-full cursor-pointer text-center;

    pointer-events: all;
    white-space: unset;
    overflow: visible;
    left: unset;
    max-width: unset;
    transition: 0.1s ease-in-out all;
  }

  .tag-filter input[type="checkbox"]:checked {
    @apply bg-yellow;
  }
  .tag-filter input[type="checkbox"]:checked + label {
    @apply text-black;
  }
  .tag-filter-input.tags-checked input[type="checkbox"]:not(:checked) {
    @apply bg-gray-400;
  }
  .tag-filter-input.tags-checked input[type="checkbox"]:not(:checked) + label {
    @apply text-gray-dark;
  }

  .tag-filter input[type="checkbox"]::after,
  .tag-filter input[type="checkbox"]:checked::after {
    content: unset;
    position: static;
    left: unset;
    top: unset;
    width: unset;
    height: unset;
  }
  .tag-filter input[type="checkbox"]:active,
  .tag-filter input[type="checkbox"]:focus,
  .tag-filter input[type="checkbox"]:hover {
    @apply shadow-blue;
  }

  .date-range {
    @apply inline-flex flex-row items-center h-10;

    width: calc(50% - 15.75px);
  }

  .date-range + .pre-label {
    @apply w-8 inline-flex text-center items-center justify-center;
  }

  .time-range-input {
    @apply relative flex flex-col items-center overflow-visible;

    height: auto !important;
  }

  .time-range-input .button[data-has-value] {
    @apply bg-yellow text-black;
  }

  .time-range-container {
    height: 322px;
  }

  .time-range-background .item {
    @apply flex flex-row items-center justify-center relative text-tiny font-bold;

    height: 18.8px;
  }

  .time-range-background .selected {
    @apply border-px border-blue bg-blue-300 border-t-0 border-b-0;
  }

  .time-range-background .selected.start {
    @apply rounded-tl-lg rounded-tr-lg border-t-px pb-px;
  }

  .time-range-background .selected.end {
    @apply rounded-bl-lg rounded-br-lg border-b-px pt-px;
  }

  .time-range-input input[type="range"] {
    @apply absolute h-full appearance-none bg-none pointer-events-none;

    width: 320px;
    transform: rotate(90deg);
  }

  /* We're styling the drag handles instead */
  .time-range-input input[type="range"]:active,
  .time-range-input input[type="range"]:focus {
    @apply outline-none;
  }

  .time-range-input
    input[type="range"].start:focus
    ~ .time-range-background
    .start.selected,
  .time-range-input
    input[type="range"].end:focus
    ~ .time-range-background
    .end.selected {
    @apply z-1;

    box-shadow: 0 0 2px 1px theme("colors.blue.DEFAULT");
  }

  .time-range-input input[type="range"]::-moz-range-track,
  .time-range-input input[type="range"]::-ms-track,
  .time-range-input input[type="range"]::-webkit-slider-runnable-track {
    @apply w-full h-full pointer-events-none appearance-none bg-transparent;

    background: none;
  }

  .time-range-input input[type="range"]::-ms-track {
    @apply bg-black;
  }

  /* These have to be split out for some reason */
  .time-range-input input[type="range"]::-moz-range-thumb {
    @apply h-full px-0 py-9 appearance-none bg-transparent border-transparent;

    width: 18.8px;
    pointer-events: all;
    cursor: row-resize;
  }
  .time-range-input input[type="range"]::-webkit-slider-thumb {
    @apply h-full px-0 py-9 appearance-none bg-transparent border-transparent;

    width: 18.8px;
    pointer-events: all;
    cursor: row-resize;
  }
  /* -ms-thumb doesn't work on Edge with mouse, ignoring for now */
  .time-range-input input[type="range"]::-ms-slider-thumb {
    @apply h-full px-0 py-9 appearance-none bg-transparent border-transparent;

    width: 18.8px;
    pointer-events: all;
    cursor: row-resize;
  }

  @screen touch {
    .time-range-input input[type="range"] {
      height: 100vw;
      transform-origin: 50vw;
    }
  }

  .autocomplete-field {
    @apply relative w-full;
  }

  .autocomplete-field ul[role="listbox"] {
    @apply absolute top-full left-0 right-0 m-0 z-5 bg-white overflow-y-scroll overflow-scroll-touch border-px border-gray-border;

    list-style: none;
    max-height: 160px;
  }

  .autocomplete-field [role="option"]:focus,
  .autocomplete-field [role="option"]:hover,
  .autocomplete-field [role="option"]:active {
    @apply bg-blue text-white;
  }

  .embed-autocomplete-field {
    @apply relative;
  }

  .embed-autocomplete-field ul[role="listbox"] {
    @apply absolute top-full left-0 right-0 m-0 mt-1 p-5 z-5 bg-white overflow-y-scroll overflow-scroll-touch border-px border-gray-border rounded;

    list-style: none;
    max-height: 300px;
    box-shadow: 0px 0px 4px 0px rgba(203, 211, 219, 0.55);
  }

  .filter-autocomplete-field ul[role="listbox"] {
    @apply absolute top-full left-0 right-0 m-0 mt-1 p-5 z-5 bg-white border-px border-gray-border rounded;

    list-style: none;
    box-shadow: 0px 0px 4px 0px rgba(203, 211, 219, 0.55);
  }

  .embed-autocomplete-field [role="option"]:focus,
  .embed-autocomplete-field [role="option"]:hover,
  .filter-autocomplete-field [role="option"]:focus,
  .filter-autocomplete-field [role="option"]:hover {
    @apply bg-yellow-pale;
  }

  .embed-autocomplete-field input[type="checkbox"]:checked::after,
  .filter-autocomplete-field input[type="checkbox"]:checked::after {
    @apply bg-blue text-white text-sm;
  }

  .filter-autocomplete-field input[type="checkbox"] {
    @apply p-0 min-w-0 w-7 min-h-0 h-7;
  }

  .scale-input {
    @apply cursor-pointer text-gray-bg;
    @apply text-blue;

    font-size: 32px;
  }

  input[type="radio"] ~ .scale-input:hover {
    @apply bg-blue-twitter;
  }

  input[type="radio"]:checked ~ .scale-input {
    @apply text-white;
    @apply bg-blue-dark;
  }

  .input[type="color"] {
    padding: 0px;
    width: 40px;
  }
  .input[type="color"]::-webkit-color-swatch-wrapper {
    @apply p-0;
  }
  .input[type="color"]::-webkit-color-swatch {
    @apply border-none;
  }
}
